import * as React from "react";
import { StaticImage } from "gatsby-plugin-image";
import Layout from "@layout/index";
import BottomLayout from "@/layout/BottomLayout";
import GraphicText from "@/layout/GraphicText";

import Header from "@components/head";

import {
  Text,
  List,
  Button,
  ListItem,
  Box,
  Link as ChakraLink,
  VStack,
  Flex,
} from "@chakra-ui/react";
import TopCardWrap from "@/content/use-cases/TopCardWrap";
import { HOST_URL } from "@/utils/constant";

const { useState } = React;

export const Head = () => {
  return (
    <Header
      ogUrl={`${HOST_URL}/use-cases/team-meetings`}
      title="Bring More Efficiency and Engagement to Internal Team Meetings | Airgram.io"
      description="Airgram helps align your team on the ultimate goals through shared meeting agendas, real-time transcription, timestamped notes and HD video recordings."
    />
  );
};

const IndexPage = () => {
  return (
    <Layout
      px="24px"
      bottomLayout={
        <BottomLayout
          signGtmId="web_sign_up_cta_btn_use_case"
          description="Bring energy, focus, and alignment to your team meetings"
          buttonText="Get Started Free"
          buttonLink="https://app.airgram.io/signup"
        />
      }
    >
      <TopCardWrap>
        <Text w="80%" textAlign="center" fontSize="32px" as="h1">
          Have a clear, efficient meeting to align the team on the ultimate goal
        </Text>
        <Text fontSize={18} textAlign="center">
          Whether it’s a decision-making meeting or a brainstorming, your team
          will benefit.
        </Text>

        <ChakraLink
          _hover={{
            textDecor: "none",
          }}
          lineHeight="52px"
          target="_blank"
          mt="24px"
          href="https://app.airgram.io/signup"
        >
          <Button
            data-gtm="web_sign_up_cta_btn_use_case"
            size="lg"
            colorScheme="brand"
          >
            Get Started Free
          </Button>
        </ChakraLink>
      </TopCardWrap>

      <VStack my="80px" gap={{ base: "40px", md: "80px", lg: "120px" }}>
        {/* 左边图片，右边文字的布局 */}
        <GraphicText
          placement="right"
          title="Ensure team alignment before the discussion"
          slot={
            <StaticImage
              loading="lazy"
              width={540}
              style={{ borderRadius: "12px" }}
              src="https://a.storyblok.com/f/167495/990x550/3e2e0359cf/usecaseteam1.png"
              alt="Use Case Team"
            />
          }
        >
          <Flex
            flexDir="column"
            gap="12px"
            flex={1}
            bgColor="white"
            borderRadius="12px"
            color="#220247"
          >
            <Text fontSize={18}>
              Create agenda with pre-built templates to spark active and
              structured discussion. Start the call with a default agenda timer
              for each crucial talking point.
            </Text>
          </Flex>
        </GraphicText>

        {/* 左边文字，右边图片的布局 */}
        <GraphicText
          title="Better concentration without manual operation"
          slot={
            <StaticImage
              loading="lazy"
              width={540}
              style={{ borderRadius: "12px" }}
              src="https://a.storyblok.com/f/167495/990x550/9c2ec78954/usecaseteam2.png"
              alt="Google Meet Transcript"
            />
          }
        >
          <Flex
            flexDir="column"
            gap="12px"
            flex={1}
            bgColor="white"
            borderRadius="12px"
            color="#220247"
          >
            <Text fontSize={18}>
              A multilingual AI assistant records and transcribes Zoom, Google
              Meet or MS Teams calls. Increase concentration on the actual
              discussion with the help of:
            </Text>

            <List pl={0}>
              <ListItem>🔹 Real-time transcription</ListItem>
              <ListItem>🔹 Auto join Zoom calls</ListItem>
              <ListItem>🔹 One-click recording instant Zoom calls</ListItem>
            </List>

            <Text fontSize={18}>
              Listen to different speakers separately after the call. Remove the
              blocks of inefficiency and distractions.
            </Text>
          </Flex>
        </GraphicText>

        <GraphicText
          placement="right"
          title="Get clear on action items"
          slot={
            <StaticImage
              loading="lazy"
              width={540}
              style={{ borderRadius: "12px" }}
              src="https://a.storyblok.com/f/167495/990x550/0495d86756/usecaseteam3.png"
              alt="Google Meet Collaborative Notes"
            />
          }
        >
          <Flex
            flexDir="column"
            gap="12px"
            flex={1}
            bgColor="white"
            borderRadius="12px"
            color="#220247"
          >
            <Text fontSize={18}>
              Assign the tasks to specific individuals with due dates so that
              everyone can contribute to achieving the ultimate goal. Track
              action items in one place. Make sure all tasks are completed on
              time.
            </Text>
          </Flex>
        </GraphicText>

        <GraphicText
          title="Create clips, share meeting notes"
          slot={
            <StaticImage
              loading="lazy"
              width={540}
              style={{ borderRadius: "12px" }}
              src="https://a.storyblok.com/f/167495/990x550/1c05691ee4/usecaseteam4.png"
              alt="Google Meet Collaborative Notes"
            />
          }
        >
          <Flex
            flexDir="column"
            gap="12px"
            flex={1}
            bgColor="white"
            borderRadius="12px"
            color="#220247"
          >
            <Text fontSize={18}>
              Create clips to extract key highlights. Share them with colleagues
              to keep everyone updated on important meetings.
            </Text>
            <Text fontSize={18}>
              Share meeting notes via Slack, Notion, Google Docs, or Microsoft
              Word. Download the video recording when necessary.
            </Text>
            <Text fontSize={18}>
              The circulation of all types of meeting notes forms a shared
              database. Your teammates can organize their own knowledge base
              with the retained information.
            </Text>
          </Flex>
        </GraphicText>
      </VStack>
    </Layout>
  );
};

export default IndexPage;
